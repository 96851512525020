<template>
    <v-simple-table class="detail-table">
        <thead>
            <tr>
                <th>{{ $t('normal-game-detail', 'Leg') }}</th>
                <th>{{ $t('normal-game-detail', 'Date') }}</th>
                <th>{{ $t('normal-game-detail', 'Rating') }}</th>
                <template v-if="$route.name === 'getCricketNormalRatingDetail'">
                    <th>{{ $t('normal-game-detail', 'MPR') }}</th>
                    <th>{{ $t('normal-game-detail', 'Marks') }}</th>
                </template>
                <template v-else-if="$route.name === 'getCountUpNormalRatingDetail'">
                    <th>{{ $t('normal-game-detail', 'ObtainedScore') }}</th>
                </template>
                <template v-else>
                    <th>{{ $t('normal-game-detail', 'AVG') }}</th>
                    <th>{{ $t('normal-game-detail', 'ObtainedScore') }}</th>
                </template>
                <th>{{ $t('normal-game-detail', 'Darts') }}</th>
                <th>{{ $t('normal-game-detail', 'GameType') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in result" :key="`tr-${index}`">
                <th>{{ index+1 }}</th>
                <td>
                    <p class="ma-0">{{ item.endDate.split(' ')[0] }}</p>
                    <p class="ma-0">{{ item.endDate.split(' ')[1] }}</p>
                </td>
                <td>{{ item.rating }}</td>
                <template v-if="$route.name === 'getCricketNormalRatingDetail'">
                    <td>{{ item.playersMPR }}</td>
                    <td>{{ item.playersMarkCount }}</td>
                </template>
                <template v-else-if="$route.name === 'getCountUpNormalRatingDetail'">
                    <td>{{ item.playersScore }}</td>
                </template>
                <template v-else>
                    <td>{{ item.playersPPR }}</td>
                    <td>{{ item.playersScore }}</td>
                </template>
                <td>{{ item.playersDartCount }}</td>
                <td>{{ item.gameType }}</td>
            </tr>
        </tbody>
    </v-simple-table>
</template>
<script>
export default {
    name: 'GameDetialTable',
    props: ['result'],
    components: {},
    data() {
        return {}
    },
    created() {},
    computed: {},
    methods: {}
}

</script>
