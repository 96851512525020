<template>
    <div class="normal-game-detail-page">
        <div v-if="noData" class="no-data">{{ $t('golbal', 'No Data') }}</div>
        <template v-else>
            <v-container fluid class="pa-0" v-if="errorCode === 'E00000'">
                <div class="index-page">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th colspan="2">{{ $t('normal-game-detail', $route.name) }}</th>
                            </tr>
                            <tr>
                                <th>{{ $t('normal-game-detail', 'Rating') }}</th>
                                <td>{{ result.rating }}</td>
                            </tr>
                            <tr>
                                <template v-if="$route.name === 'getCricketNormalRatingDetail'">
                                    <th>{{ $t('normal-game-detail', 'MPR') }}</th>
                                    <td>{{ result.mpr }}</td>
                                </template>
                                <template v-else-if="$route.name === 'getCountUpNormalRatingDetail'">
                                    <th>{{ $t('normal-game-detail', 'ScorePerLeg') }}</th>
                                    <td>{{ result.score }}</td>
                                </template>
                                <template v-else>
                                    <th>{{ $t('normal-game-detail', 'AVG') }}</th>
                                    <td>{{ result.avg }}</td>
                                </template>
                            </tr>
                            <tr>
                                <th>{{ $t('normal-game-detail', 'Total Legs') }}</th>
                                <td>{{ result.totalLegs }}</td>
                            </tr>
                        </thead>
                    </v-simple-table>
                </div>
                <router-view :result="result.result" />
            </v-container>
            <div v-else-if="errorCode !== null" class="no-data">
                <p>{{ $t('apiError', 'E00003') }}</p>
                <p class="mt-3 msg">{{ errorMsg }}</p>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    name: 'GameDetail',
    props: ['playerId'],
    components: {},
    data() {
        return {
            noData: false,
            errorCode: null,
            errorMsg: null,
            result: {}

        }
    },
    created() {
        if (typeof this.playerId === 'undefined') {
            this.noData = true
        } else {
            if (typeof this.$route.name === 'undefined') {
                this.noData = true
            } else {
                this.fetchData(this.$route.name)
            }
        }
    },
    methods: {
        fetchData(path) {
            axios
                .get('/api/getGameDetailData', { params: { playerId: this.playerId, path: path } })
                .then(response => {
                    let data = JSON.parse(response.data.data)
                    this.errorCode = data.errorCode
                    if (this.errorCode === "E00000") {
                        this.result = data.data
                        //console.log(this.result)
                    } else {
                        this.errorMsg = this.errorCode
                    }
                }).catch(error => {
                    this.errorCode = 'E00003'
                    if (error.response) {
                        // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
                        console.log(error.response.data)
                        console.log(error.response.status)
                        console.log(error.response.headers)
                        this.errorMsg = error.response.data
                    } else if (error.request) {
                        // 请求已经成功发起，但没有收到响应
                        // `error.request` 在浏览器中是 XMLHttpRequest 的实例，
                        // 而在node.js中是 http.ClientRequest 的实例
                        console.log(error.request)
                        this.errorMsg = error.request
                    } else {
                        // 发送请求时出了点问题
                        console.log('Error', error.message)
                        this.errorMsg = error.message
                    }
                })
        }
    }
}

</script>
